/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
@import url("https://example.com/helvetica-neue.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Island+Moments&display=swap");

.custom-bg-top {
  background: linear-gradient(
      0deg,
      rgba(0, 19, 77, 0.32) 0%,
      rgba(0, 19, 77, 0.32) 100%
    ),
    linear-gradient(164deg, #000 12.94%, rgba(0, 0, 0, 0) 88.81%);
}

.object-custom {
  object-fit: cover;
  object-position: center 25%;
}

.image-container:hover p {
  opacity: 1;
}

@media (max-width: 1535px) {
  .object-custom {
    object-fit: cover;
    object-position: center;
  }
}

.object-custom-2 {
  object-fit: cover;
  object-position: center 60%;
}

@media (max-width: 1535px) {
  .object-custom-2 {
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: 1350px){
  .custom-width-testimonial{
    max-width: 900px;
  }
}

@media (max-width: 1280px){
  .custom-width-testimonial{
    max-width: 830px;
  }
}